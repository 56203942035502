<template>
  <b-card-actions action-collapse title="Daftar Pengguna">
    <!-- ref="refreshCard" -->
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-button
        v-if="allowCreate()"
          variant="primary"
          @click.prevent="add"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          Tambah
        </b-button>
        <b-modal v-if="allowCreate() || allowUpdate()" v-model="showModal" id="modal-tambah" size="xl" title="Form Pengguna">
          <validation-observer ref="form">
            <b-form>
              <b-row>
                <b-col sm="12" md="6" lg="4">
                  <b-form-group label="Pilih Karyawan" label-for="karyawan">
                    <validation-observer
                      #default="{ errors }"
                      name="karyawan"
                      rules="required"
                    >
                      <b-form-select
                        :state="errors.length > 0 ? false : null"
                        v-model="form.karyawan_id"
                        :options="dataKaryawan"
                        name="karyawan"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-observer>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="6" lg="4">
                  <b-form-group label="Pilih Level" label-for="level">
                    <validation-provider
                      #default="{ errors }"
                      name="level"
                      rules="required"
                    >
                      <b-form-select
                        :state="errors.length > 0 ? false : null"
                        v-model="form.level_id"
                        :options="dataLevel"
                        name="level"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>

          <template #modal-footer>
            <b-row>
              <b-col cols="12">
                <b-button
                  type="reset"
                  variant="outline-secondary"
                  @click.prevent="showModal = false"
                >
                  Batal
                </b-button>
                <b-button
                  type="submit"
                  @click.prevent="submit"
                  variant="primary"
                  class="ml-1"
                >
                  {{ label }}
                </b-button>
              </b-col>
            </b-row>
          </template>
        </b-modal>
        <b-modal v-if="allowCreate() || allowUpdate()" v-model="showModalPin" id="modal-pin" size="xl" title="Reset PIN">
          <validation-observer ref="form2">
            <b-form>
              <b-row>
                <b-col sm="12" md="6" lg="4">
                  <b-form-group label="Nama Karyawan" label-for="karyawan">
                    <validation-observer
                      #default="{ errors }"
                      name="karyawan"
                      rules="required"
                    >
                      <b-form-select
                        :state="errors.length > 0 ? false : null"
                        v-model="form.karyawan_id"
                        :options="dataKaryawan"
                        disabled
                        name="karyawan"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-observer>
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="12" md="6" lg="4">
                  <b-form-group label="ID" label-for="id">
                    <validation-observer
                      #default="{ errors }"
                      name="id"
                      rules="required"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="form2.id"
                        name="id"
                        />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-observer>
                  </b-form-group>
                </b-col> -->

                <b-col sm="12" md="6" lg="4">
                  <b-form-group label="PIN" label-for="pin">
                    <validation-provider
                      #default="{ errors }"
                      name="pin"
                      rules="required|length:4"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="form2.pin"
                        name="pin"
                        type="number"
                        />
                        <!-- :options="dataLevel" -->
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>

          <template #modal-footer>
            <b-row>
              <b-col cols="12">
                <b-button
                  type="reset"
                  variant="outline-secondary"
                  @click.prevent="showModalPin = false"
                >
                  Batal
                </b-button>
                <b-button
                  type="submit"
                  @click.prevent="submitPin"
                  variant="primary"
                  class="ml-1"
                >
                  {{ label }}
                </b-button>
              </b-col>
            </b-row>
          </template>
        </b-modal>
        <b-modal v-if="allowCreate() || allowUpdate()" v-model="showModalPassword" id="modal-password" size="xl" title="Reset Password">
          <validation-observer ref="form3">
            <b-form>
              <b-row>
                <b-col sm="12" md="6" lg="4">
                  <b-form-group label="Nama Karyawan" label-for="karyawan">
                    <validation-observer
                      #default="{ errors }"
                      name="karyawan"
                      rules="required"
                    >
                      <b-form-select
                        :state="errors.length > 0 ? false : null"
                        v-model="form.karyawan_id"
                        :options="dataKaryawan"
                        disabled
                        name="karyawan"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-observer>
                  </b-form-group>
                </b-col>
                <!-- <b-col sm="12" md="6" lg="4">
                  <b-form-group label="ID" label-for="id">
                    <validation-observer
                      #default="{ errors }"
                      name="id"
                      rules="required"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="form2.id"
                        name="id"
                        />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-observer>
                  </b-form-group>
                </b-col> -->

                <b-col sm="12" md="6" lg="4">
                  <b-form-group label="Password" label-for="password">
                    <validation-provider
                      #default="{ errors }"
                      name="password"
                      rules="required|min:7"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="form3.password"
                        name="password"
                        />
                        <!-- :options="dataLevel" -->
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>

          <template #modal-footer>
            <b-row>
              <b-col cols="12">
                <b-button
                  type="reset"
                  variant="outline-secondary"
                  @click.prevent="showModalPassword = false"
                >
                  Batal
                </b-button>
                <b-button
                  type="submit"
                  @click.prevent="submitPassword"
                  variant="primary"
                  class="ml-1"
                >
                  {{ label }}
                </b-button>
              </b-col>
            </b-row>
          </template>
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group
          label="Sort"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          small
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(id)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(karyawan)="data">
            <span>{{ data.item.karyawan?.nama_lengkap }}</span>
          </template>

          <template #cell(level)="data">
            {{ data.item.level ? data.item.level.nama_level : "-" }}
          </template>

          <template #cell(actions)="data">
            <section v-if="!itsMe(data.item) && !isAdminUser(data.item)">
            
              <b-button
                v-b-tooltip.hover.right="'Reset PIN'"
                size="sm"
                class="mr-1"
                @click.prevent="pin(data.item)"
                variant="outline-warning"
              >Reset PIN
                <feather-icon icon="LockIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover.right="'Reset Password'"
                size="sm"
                class="mr-1"
                @click.prevent="password2(data.item)"
                variant="outline-primary"
              >Reset Password
                <feather-icon icon="KeyIcon" />
              </b-button>
              <b-button
              v-if="allowUpdate()"
                v-b-tooltip.hover.right="'Ubah'"
                size="sm"
                class="mr-1"
                @click.prevent="edit(data.item)"
                variant="outline-info"
              >Ubah
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
              v-if="allowDelete()"
                v-b-tooltip.hover.right="'Hapus'"
                @click.prevent="remove(data.item)"
                size="sm"
                class="mr-1"
                variant="outline-danger"
              >Hapus
                <feather-icon icon="TrashIcon" />
              </b-button>
            </section>
            <b-badge v-else-if="itsMe(data.item)" variant="success" class="ml-1">Saya</b-badge>
          </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">
                  {{ key }}: {{ value }}
                </li>
              </ul>
            </b-card>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BTable,
  VBTooltip,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BCardText,
} from "bootstrap-vue";

const PASSWORD_LENGTH = 7;
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    VBTooltip,
    BModal,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BCardText,
    required,
  email, confirmed, password,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      required,
  email, confirmed, password,
      label: "Submit",
      showModal: false,
      showModalPin: false,
      showModalPassword: false,
      id: null,
      form: {
        level_id: null,
        karyawan_id: null,
      },
      form2: {
        id: null,
        pin: null,
      },
      form3: {
        id: null,
        password: null,
      },
      dataLevel: [],
      dataKaryawan: [],
      optjk: [
        { value: "1", text: "Laki-laki" },
        { value: "2", text: "Perempuan" },
      ],
      perPage: 20,
      pageOptions: [20, 40, 60],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: [
        {
          key: "id",
          label: "NO",
        },
        { key: "karyawan", label: "Nama", sortable: true },
        { key: "level", label: "Level", sortable: true },
        "email",
        { key: "no_hp", label: "No. Handphone" },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.getData();
    this.getDataKaryawan();
    this.getDataLevel();
  },
  methods: {
    isAdminUser(user) {
      const isLevelAdmin = user.level?.nama_level.toLowerCase() === 'admin'
      if(isLevelAdmin) {
        return true
      }

      return false
    },
    itsMe(user) {
      const myUserId = this.user.id
      const userId   = user.id

      const itsMe = myUserId === userId
      if(itsMe) {
        return true
      }

      return false
    },
    remove(item) {
      if(this.itsMe(item) || this.isAdminUser(item)) {
        const message = this.isAdminUser(item) ? 'Tidak bisa merubah data admin' : 'Tidak bisa mengubah data sendiri'

        this.displayError({
          message
        })

        return false
      }
      this.$swal({
        title: "Anda yakin?",
        text: `Data Pengguna ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const payload = {
            id: item.id,
            karyawan_id: item.karyawan ? item.karyawan.id : null,
            level_id: item.level ? item.level.id : null,
            fungsi: 2,
          };
          this.$store
            .dispatch("pengguna/save", [payload])
            .then(() => {
              this.resetForm();
              this.getData();
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        }
      });
    },
    edit(item) {

      if(this.itsMe(item) || this.isAdminUser(item)) {
        const message = this.isAdminUser(item) ? 'Tidak bisa merubah data admin' : 'Tidak bisa mengubah data sendiri'

        this.displayError({
          message
        })

        return false
      }

      this.id = item.id;
      if (item.karyawan) {
        this.form.karyawan_id = item.karyawan.id;
      }

      if (item.level) {
        this.form.level_id = item.level.id;
      }

      this.showModal = true;
    },
    pin(item) {
      if(this.itsMe(item) || this.isAdminUser(item)) {
        const message = this.isAdminUser(item) ? 'Tidak bisa merubah data admin' : 'Tidak bisa mengubah data sendiri'

        this.displayError({
          message
        })

        return false
      }
      this.form2.id = item.id
      if (item.karyawan) {
        this.form.karyawan_id = item.karyawan.id;
      }
      this.showModalPin = true;
    },
    password2(item) {
      if(this.itsMe(item) || this.isAdminUser(item)) {
        const message = this.isAdminUser(item) ? 'Tidak bisa merubah data admin' : 'Tidak bisa mengubah data sendiri'

        this.displayError({
          message
        })

        return false
      }
      this.form3.id = item.id;
      if (item.karyawan) {
        this.form.karyawan_id = item.karyawan.id;
      }

      this.showModalPassword = true;
    },
    getData() {
      this.$store
        .dispatch("pengguna/getData")
        .then(() => {
          const users = JSON.parse( JSON.stringify( this.$store.state.pengguna.datas ) )
          this.items = users.filter(user => {
            if(!this.isAdmin) {
              return !this.isAdminUser(user)
            }

            return user
          });
          this.totalRows = this.items.length
        })
        .catch((e) => this.displayError(e));
    },
    resetForm() {
      this.id = null;
      this.form = {
        karyawan_id: null,
        level_id: null,
      };
    },
    add() {
      this.resetForm();
      this.showModal = true;
    },
    getDataKaryawan() {
      this.$store
        .dispatch("karyawan/getData")
        .then(() => {
          let karyawan = JSON.parse(JSON.stringify(this.$store.state.karyawan.datas));
          karyawan.map((item) => {
            item.value = item.id;
            item.text = item.nama_lengkap;
          });

          this.dataKaryawan = karyawan;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getDataLevel() {
      this.$store
        .dispatch("level/getData")
        .then(() => {
          let level = JSON.parse(JSON.stringify(this.$store.state.level.datas));
          level.map((item) => {
            item.value = item.id;
            item.text = item.nama_level;
          });

          this.dataLevel = level;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    submit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.label = "Loading...";
          if (this.id) {
            this.form.id = this.id;
          }
          this.$store
            .dispatch("pengguna/save", [this.form])
            .then(() => {
              this.label = "Submit";
              this.getData();
              this.showModal = false;
              this.displaySuccess({
                  message: "Pengguna berhasil ditambah"
                });
            })
            .catch((e) => {
              this.label = "Submit";
              this.displayError(e);

              return false;
            });
        }
      });
    },
    submitPin() {
      this.$refs.form2.validate().then((success) => {
        if (success) {
          this.label = "Loading...";
          if (this.id) {
            this.form2.id = this.id;
          }
          this.$store
            .dispatch("pengguna/resetPin", this.form2)
            .then(() => {
              this.label = "Submit";
              this.getData();
              this.showModalPin = false;
              this.displaySuccess({
                  message: "PIN berhasil direset"
                });
            })
            .catch((e) => {
              this.label = "Submit";
              this.displayError(e);

              return false;
            });
        }
      });
    },
    submitPassword() {
      this.$refs.form3.validate().then((success) => {
        if (success) {
          const {password} = this.form3
          if(
            !password ||
            password.length > PASSWORD_LENGTH
          ) {
            this.displayError({
              message: 'Password harus 7 karakter!'
            })
            return false
          }

          this.label = "Loading...";
          if (this.id) {
            this.form3.id = this.id;
          }
          this.$store
            .dispatch("pengguna/resetPassword", this.form3)
            .then(() => {
              this.label = "Submit";
              this.getData();
              this.showModalPassword = false;
              this.displaySuccess({
                  message: "Password berhasil direset"
                });
            })
            .catch((e) => {
              this.label = "Submit";
              this.displayError(e);

              return false;
            });
        }
      })
      .catch(e => {
        this.displayError(e)
        return false
      });
    },
  }
};
</script>
